@import "index.scss";

.hero {
	background-color: #1E293B;
	height: 90vh;
	@include short {
		height: calc(99vh);
	}
	color:white;
	display: flex;
	flex-direction: column;

	background-image: url("/swoosh_bottom_left.svg");
	background-repeat: no-repeat;
	background-position: left bottom;
	@include lg {
		background-image: url("/swoosh_bottom_left.svg"), url("/swoosh_top_right.svg");
		background-position: left bottom, right top;
		background-repeat: no-repeat, no-repeat;
	}

	&__heading {
		&__container {
			position: absolute;
			width:100%;
		}

		padding-left:1rem;
		padding-right:1rem;
		@include sm {
			width: 30rem;
		}
		@include md {
			width: 50rem;
		}
		@include lg {
			width: 60rem;
		}
		@include xl {
			width: 70rem;
		}

		margin-left: auto;
		margin-right: auto;

		display:flex;
		justify-content: space-between;
		align-items: center;

		&__title {
			font-style: italic;
			font-size: 2.5rem;
			justify-self: flex-start;
		}
	}

	&__content {
		flex-grow: 1;
		height: 100%;

		padding-left:1rem;
		padding-right:1rem;
		@include sm {
			width: 30rem;
		}
		@include md {
			width: 50rem;
		}
		@include lg {
			width: 60rem;
		}
		@include xl {
			width: 70rem;
		}
		margin-left: auto;
		margin-right: auto;

		display: grid;
		gap: 1.5rem;
		grid-template-columns: 1fr; //calc(50% - 0.75rem) calc(50% - 0.75rem);
		@include lg {
			grid-template-columns: 1fr 1fr; //calc(50% - 0.75rem) calc(50% - 0.75rem);
		}
		grid-template-rows: 100%;

		&__quote {
			display: flex;
			flex-direction: column;
			justify-content: center;
			gap: 1rem;

			&__title {
				font-size: 2.5rem;
				line-height: 1rem;
			}

			&__text {
				color: #F8FAFC;
				font-size: 2rem;
				font-weight: 600;
				display: flex;
				flex-direction: column;
				gap: 0.5rem;
			}

			&__credit {
				display:flex;
				gap: 1rem;
				align-items: center;

				&__image {
					width: 4.75rem;
					border-radius: 4.75rem;
					opacity: 0.8;
					border: 2px solid white;
				}

				&__details {
					display: flex;
					flex-direction: column;
					&__name {
						line-height: 1rem;
						font-weight: 700;
						font-size: 1.5rem;
					}

					&__desc {
						font-size: 1.5rem;
						font-weight: 400;
					}
				}
			}
		}
	}

	&__payflow_container {
		flex-direction:column;
		justify-content: center;
		margin-top:4rem;
		display: none;
		@include lg {
			display:flex;
		}
	}

	&__payflow {
		height: calc(100% - 14rem);
		max-height: calc(100% - 14rem);
		/*@include short {
			max-height: calc(100% - 4rem);
		}*/

		@include shorter {
			height: calc(100% - 8rem);
			max-height: calc(100% - 8rem);
		}
		border: 2px solid #000000;
		box-shadow: inset 0px 0px 4px 4px rgba(0, 0, 0, 0.25);
		border-radius: 6px;

		&__support {
			display: flex;
			align-items: center;
			gap: 1ch;
			color: #e2e8f0;
			padding-top: 0.5rem;
		}

		&--nohero {
			height: 100%;
		}
	}
}

.home_section {
	padding-top: 2rem;
	padding-left:1rem;
	padding-right:1rem;
	@include sm {
		width: 30rem;
	}
	@include md {
		width: 50rem;
	}
	@include lg {
		width: 60rem;
	}
	@include xl {
		width: 70rem;
	}

	margin-left: auto;
	margin-right: auto;

	display:flex;
	flex-direction: column;
	justify-content: space-between;


	&__title {
		font-size: 2rem;
		margin:0;
		margin-bottom: 0.5rem;
	}

	&__cards {
		display: grid;

		grid-template-columns: 1fr;
		@include lg {
			grid-template-columns: 1fr 1fr;
		}
		@include xl {
			grid-template-columns: 1fr 1fr 1fr;
		}
		gap: 1rem;
	}

	&--payflow {
		@include lg {
			display: none;
		}
	}
}

.minicard {
	background-color: white;
	box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 0.75rem;
	padding: 1.75rem;
}

.tutorcard {
	gap: 1rem;
	display: flex;
	flex-direction: column;

	&__header {
		gap: 1rem;
		display: flex;
		align-items: center;
		&__image {
			width: 5rem;
			clip-path: circle();
		}

		&__details {
			font-size: 1.5rem;
			&__name {
				color:black;
				font-weight: 700;
			}

			&__position {
				color: #334155;
			}
		}
	}

	&__body {
		font-size: 1.5rem;
	}

}

.quotecard {
	height: 30vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	
	&__body {
		font-size: 1.5rem;
		line-height: 2rem;
		font-weight: 600;
	}

	&__details {
		display: flex;
		gap: 0.5ch;
		font-size: 1.25rem;
		&__name {
			font-weight: 700;
		}

		&__position {
			color: #334155;
		}
	}
}

.home_section_container {
	background-color: #F8FAFC;
	padding-bottom: 3rem;
	background-image: url("../public/bg-noise.png");
	background-blend-mode: multiply;
}

.home_card {
	border-radius: 0.75rem;
	background-color: white;
	padding: 1.75rem;
	gap: 1rem;
	box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.25);

	&__header{
		display: flex;
		align-items: center;
		gap: 2ch;
		&__icon {
			transform: scale(2);
		}

		&__title {
			font-weight: 600;
			font-size: 2rem;

			&--small {
				font-size: 1.5rem;
			}
		}

		&__icon_container {
			clip-path: circle();
			display: flex;
			align-items: center;
			justify-content: center;
			width: 5rem;
			height: 5rem;
			font-size:2.5rem !important;
			background-color: #CBD5E1;
		}
	}

	&__body {
		font-size: 1.5rem;
		line-height: 2rem;
	}
}
