@import './index.scss';

::-webkit-scrollbar {
	// from stackoverflow (https://stackoverflow.com/questions/35663653/make-a-scrollbar-always-visible-in-a-div-chrome)
  -webkit-appearance: none;
  width: 10px;
	background-color: #00000000;
}

::-webkit-scrollbar-thumb {
	// from stackoverflow (https://stackoverflow.com/questions/35663653/make-a-scrollbar-always-visible-in-a-div-chrome)
  border-radius: 5px;
  background-color: rgba(0,0,0,.5);
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}

.payflow {
	color: black;
	background-color: white;
	padding: 1.5rem;
	display: flex;
	flex-direction: column;
	// because for lg, this will be located in the hero
	@include lg {
		overflow: scroll;
	}
	gap: 1rem;

	&--embed {
		padding: 1.25ch;
	}

	&__heading {
		display: flex;
		gap: 1rem;
		align-items: center;

		&__back {
			display: flex;
			align-items: center;
			cursor: pointer;
			&--initial {
				@include sm {
					display: none;
				}
			}
		}
		&__title {
			line-height: 1rem;
			margin-top:0;
			margin-bottom:0;

			&--chips {
				display: flex;
				align-items: center;
				gap: 0.25rem;
			}
		}
	}

	&__inputs {
		display:flex;
		flex-direction: column;
		gap: 1rem;
	}

	&__inputgroup {
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
		&__title {
			line-height: 1rem;
			margin:0;
			color: #1E293B;
			font-weight: 600;
		}
		
		&__select {
			margin:0;
		}
	}

	&__assurances {
		display: flex;
		flex-direction: column;
	}

	&__submit {
		display: flex;
		flex-grow: 1;
		justify-content: flex-end;
		flex-direction: column;
		margin-top: 0.5rem;

		&__assurances {
			font-size: 0.75rem;
			font-weight: 700;
			color: #1E293B;
		}
	}

	&__appts {
		padding-top: 1rem;
		display: flex;
		flex-direction: column;
		gap: 1.5rem;
	}

	&__appt {
		border-radius: 12px;
		box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.25);
		padding: 1.5rem;
		display: flex;
		flex-direction: column;
		gap: 1rem;

		&__header {
			font-weight: 700;
			font-size: 24px;
			display: flex;
			flex-direction: column;
			@include md {
				flex-direction: row;
			}
			gap: 0.25em;
			&__weekday {
				color: black
			}

			&__time {
				color: #393939;
			}
		}
		&__chips {
			display: flex;
			gap: 0.5rem;
			flex-wrap: wrap;
			align-items: start;
		}

		&__tutor {
			display: flex;
			flex-direction: column;
			gap: 1rem;

			&__profile {
				display: flex;
				align-items: center;
				gap: 1rem;
				&__image {
					width: 4.5rem;
					clip-path: circle();
				}

				&__desc {
					&__name {
						font-weight: 700;
						font-size: 1.25rem;
						color: #0F172A;
					}

					&__qualifier {
						font-size: 1.25rem;
						color: #334155;
					}
				}
			}

			&__details {
				&__desc {
					padding-left: 5.5rem;
					&__expand {
						font-weight: 600;
						cursor: pointer;
					}
				}
			}

		}

		&__footer {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
			&__date {
				font-weight: 700;
				font-size: 1.25rem;
			}
		}
	}
}
