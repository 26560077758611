@import "index.scss";

.pay_table {
	font-size: 1.25rem;
	font-weight: 500;
	padding: 1rem;
	background-color: #e2e8f0;
	border-radius: 0.5rem;
	border-spacing: 0.5rem;
	width: 32ch;
	&__number {
		text-align: right;
		font-weight: bold;
	}

	&__container {
		display: flex;
		gap: 1rem;
		flex-direction: column;
		@include md {
			flex-direction: row;
			justify-content: flex-start;
			align-items: flex-end;
		}
	}

	&__action {
		display: flex;
		gap: 1rem;
		flex-direction: column;
		justify-content: flex-end;
	}

	&__pressure {
		font-size: 1.25rem;
	}
}

.fact_card {
	border-radius: 0.5rem;
	background-color: white;
	box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.25);

	&__title {
	}

	&__content {
	}
}

.support {
	display: flex;
	align-items: center;
	gap: 1ch;
	padding-top: 0.5rem;
}

.pressure_cooker {
	@keyframes cooker_pulse {
		0% {opacity: 0.75;}
		50% {opacity: 1}
		100% {opacity: 0.75}
	}
	animation: cooker_pulse 1s infinite;
	font-weight: bold;
	color: #d97706;
	&--urgent {
		color:#ea580c;
	}
	
	&--now {
		color:#dc2626;
	}
}

.sell_header {

	&__container {
		background-color: #1E293B;
		color: white;
	}

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 1rem;
	padding: 2rem;

	&__logo {
		font-size: 3rem;
		font-weight: bolder;
	}

	&__billboard {
		border-radius: 1rem;
		background-color: #059669;
		border: 4px solid #059669;

		&__title {
			font-size: 1.5rem;
			font-weight: 500;
			padding: 1rem;
		}

		&__number {
			font-size: 3rem;
			font-weight: bold;
			padding: 1rem;
			display: flex;
			align-items: center;
			gap: 0.5rem;
		}

		&__subtext {
			font-weight: 500;
			padding: 0.5rem;
			padding-left: 1rem;
			padding-right: 1rem;
			border-bottom-right-radius: 1rem;
			border-bottom-left-radius: 1rem;
			background-color: #052E16;
		}
	}
}
