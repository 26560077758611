@import "./index.scss";
@import "./Meeting.scss";

.dash {
	min-height: 100vh;
	display: flex;
	flex-direction: column-reverse;
	justify-content: flex-end;
	height: 100vh;

	gap: 0rem;
	@include sm {
		flex-direction: row;
		align-items:stretch;
		gap: 1rem;
	}

	@include md {
		gap: 2rem;
	}

	@include lg {
		gap: 3rem;
	}

	@include xl {
		gap: 4rem;
	}


	&__content {
		padding-top: 3.75rem;
		padding-left:3.75rem;

		&__title {
			padding-top: 4rem;
			color: #475569;
			font-weight: 600;
			font-size: 1.5rem;
			margin: 0;
		}

		&__meetings {
			display: flex;
			flex-direction: column;
			gap: 1rem;
			padding-bottom: 1rem;
		}
	}
}

.studentdash_payflow {
	width: 100%;
	@include sm {
		width: 25rem;
	}

	@include md {
		width: 35rem;
	}

	@include lg {
		width: 35rem;
	}
	@include xl {
		width: 50rem;
	}
//	min-width: 50vw;
}

.grades {
	&__sectionheader {
		color: #475569;
		font-weight:500;
		padding:0;
		font-size: 1.2rem;
		line-height: 1.2rem;
	}

	&__summary {
		align-self: stretch;
		background-color: #F8FAFC;
		box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
		border-radius: 1rem;
		display: flex;
		flex-direction: column;
		gap: 1rem;

		@include xl {
			flex-direction: row;
			gap: 4rem;
		}

		padding: 2.5rem;
		@include sm {
			margin-right: 2rem;
		}

		&__overall {
			background-color: #0F172A;
			color: white;
			font-size: 6rem;
			padding: 2rem;
			font-weight:bold;
			clip-path: circle();
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;

			&__icon {
				color: #CBD5E1;
				font-size: 2rem;
				display: flex;
				flex-direction: column-reverse;
				height: 5rem;
			}
		}

		&__categories {
			display: flex;
			flex-direction: column;
			gap: 1rem;
			@include sm {
				display: grid;
				grid-auto-flow:column dense;
				grid-template-columns: repeat(auto-fit, 1fr);
				grid-template-rows: 1fr 1fr;
			}
			width: 100%;
			gap: 1rem;
		}

		&__category {
			border-radius: 0.5rem;
			padding: 1rem;

			background-color: #CBD5E1;
			&__header {
			}

			&__earned {
				font-weight: bold;
				font-size: 2rem;
			}

			&__action {
				font-size: 0.75rem;
				font-weight: bold;
			}
		}

		&__actions {
			display: flex;
			flex-direction: column-reverse;
		}
	}

	&__newgrade {

		display: flex;
		flex-direction: column;
		gap: 1rem;

		background-color: #F8FAFC;
		box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
		border-radius: 1rem;

		padding: 1.5rem;
		@include sm {
			margin-right: 2rem;
		}

		
	}

	&__grades {
		display: flex;
		flex-wrap: wrap;
		gap:1rem;
	}

	&__grade {
		background-color: #F8FAFC;
		border-radius: 1rem;
		box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
		padding:1rem;

		&__header {
			color: #0F172A;
			&__name {
				font-weight: bold;
			}
		}

		

		&__performance {
			color: #0F172A;
			font-weight: bold;
			font-size: 2.25rem;

			&__detail {
				font-weight:400;
				color: #0F172A;
				font-size:0.875rem;
			}
		}

		&__footer {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}

		&__dates {
			line-height: 1rem;
			font-size: 0.75rem;
		}

		&__delete {
			color: #7f1d1d;
			cursor:pointer;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	// The category and gradebook setup screen (intake process that a customer or tutor sees)
	&__setup {
		@include md {
			max-width: 45rem;
		}

		border-radius: 1rem;
		box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

		padding: 2.5rem;
		@include sm {
			margin-right: 2rem;
		}

		border: 1px solid lightgray;

		&__title {
			font-size: 1.5rem;
			font-weight: 600;
			padding:0;
			margin:0;
		}

		&__tagline {
			font-size: 1.25rem;
			color: #334155;
			font-weight: 400;
			padding:0;
			margin:0;
		}

		&__hesitation {
			color: #334155;
			font-weight: 400;
			font-size: 0.75rem;
			padding:0;
			margin:0;
			padding-bottom:1rem;
		}

		&__faq {
			@include xl {
				max-width: 80ch;
			}
			margin-right: 1ch;
			line-height: 1.75em;
		}

		&__form {
			display: flex;
			flex-direction: column;
			gap:1rem;
			align-items: stretch;

			// inputs for entering a new category
			&__newcategory {
				background-color: #CBD5E1;
				padding:1.25rem;
				border-radius: 0.5rem;

				// percent sign icon in text input
				&__icon {
					color: black;
					font-size: 1.4rem;
					display: inline-flex;
					align-items: center;
				}

				&__submit {
					&--iconbutton {
						display: none !important;
						@include xl {
							display: flex !important;
						}
					}
					
					&--button {
						@include xl {
							display: none !important;
						}
					}
				}
			}

			// an entered category that is staged for submission to backend
			&__category > * {
				display: flex;
				align-items: center;
				justify-content: flex-end;
			}

			&__category {

				padding:1.25rem;
				font-size:1.25rem;
				background-color: #CBD5E1;
				color: #0F172A;
				border-radius: 0.5rem;


				// display strategy
				display: flex;
				flex-direction: column;
				gap:0.125rem;

				@include sm {
					display: grid;
					grid-template-columns: 1.5fr 1fr 2.5fr 0.25fr ;
					gap: 1rem;
				}
				
				&--empty {
					border: 4px dashed #CBD5E1;
					font-weight: 500;
					color: #475569;
					background: none;
					display: block;
				}

				&__name {
					font-weight: bold;
					vertical-align: baseline;
					text-align: left;
					justify-content: flex-start;
				}

				&__buttoncontainer {
					display: flex;
					justify-content: flex-end;
				}
			}

		}

		&__footer {
			display: flex;
			justify-content: space-between;

			&__error {
				display: flex;
				align-items: center;
			}
		}
	}
}
