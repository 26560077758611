@import "./index.scss";
@import "./Meeting.scss";

.tutor {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background-color: #E2E8F0;
	padding: 1.5rem;
	border-radius: 0.5rem;
	gap: 1rem;

	&__right {
		display: flex;
		flex-direction: column;
		@include sm {
			flex-direction: row;
		}
		justify-content: space-between;
		gap: 1rem;
		align-items: stretch;
		@include sm {
			align-items: center;
		}

	}

	&__section {
		display: flex;
		flex-direction: column;
		&--top {
			flex-direction: column;
			@include sm {
				flex-direction: row;
			}
			justify-content: space-between;
			gap: 1rem;
			@include md {
				flex-direction: row;
			}
		}
		&__title {
				font-weight: 600;
		}

		&__items {
			display: flex;
			flex-direction: column;
			gap:0.5rem;
		}
	}
	&__profile {
		width: 4rem;
		height: 4rem;
		clip-path: circle();
		cursor: pointer;
		&--archived {
			filter: grayscale(100%);
		}
	}

	&__name {
		font-size: 1.5rem;
		font-weight: 600;
		cursor: pointer;
		&--archived {
			text-decoration-line: line-through;
		}
	}

	&__summary {
		display:flex;
		gap: 1rem;
		justify-content: space-between;

		&__button_icon {
			transform: scale(1.75);
			@include sm {
				transform: scale(1);
			}
		}

		&__meetings {
			background-color: #334155;
			color:white;
			border-radius: 1rem;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: 0.75rem;
			&--needs_review {
				background-color: #9a3412;
			}

			&__number {
				font-size: 1.25rem;
				font-weight: 600;
			}
		}
	}

	&__left {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		gap: 1rem;
	}

	&__offering {
		background-color: white;
		border-radius: 0.5rem;
		padding:0.5rem;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 1ch;

		&__coursenumber {
			font-weight: bold;
		}

		&__qualification {
			flex-grow: 1;
		}

		&--archived {
			text-decoration-line: line-through;
		}

		&__button {
			cursor: pointer;
			background-color: #d1fae5;
			color:#052e16;
			&--red {
				background-color: #fee2e2;
				color:#450a0a;
			}
			padding:0.5rem;
			border-radius:5rem;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}

.booking_container {
	min-width: 20rem;
	display:flex;
	flex-direction: column;
	gap: 1rem;
	flex-grow:1;
	overflow: scroll;
	padding: 2rem;
	@include sm {
		padding: 0rem;
	}
	

	&__title {
		color: hsla(215, 19%, 35%, 1);
		font-weight: 600;
		font-size: 2.5rem;
		@include sm {
			padding-top: 5rem;
			font-size: 1.5rem;
		}

		@include sm {
			width: 30rem;
		}

		@include md {
			width: 36rem;
		}

		@include lg {
			width: 42rem;
		}
		@include xl {
			width: 50rem;
		}

		display: flex;
		justify-content: space-between;
		gap: 1rem;

		&__addbutton {
			display: flex;
			cursor: pointer;
			justify-content: center;
			align-items: center;
			background-color: #a7f3d0;
			color: #064e3b;
			width: 3rem;
			height: 3rem;
			@include sm {
				width: 2rem;
				height: 2rem;
			}
			clip-path: circle();
		}
	}

	&__bookings {
		display: flex;
		flex-direction: column;
		gap: 1rem;
	}

	&__tutors {
		display: flex;
		flex-direction: column;
		@include md {
			padding-right: 2rem;
		}

		@include lg {
			padding-right: 4rem;
		}

		@include xl {
			width: 60vw;
		}
		gap: 1rem;
	}

	&__gradebooks {
		display: grid;
		padding: 1rem;
		@include xl {
			grid-template-columns: 1fr 1fr;
		}
		gap: 2rem;
	}
}

.gradebook_summary {
	background-color: #F8FAFC;
	box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 1rem;
	padding: 2rem;
	padding-left: 3rem;
	padding-right: 3rem;
	color: #0F172A;
	display: flex;
	gap: 1.5rem;
	flex-direction: column;

	&__latestgrade {
		&__grade {
			background-color: #CBD5E1 !important;
			box-shadow: none !important;
		}
	}

	&__header {
		display: flex;
		gap: 1rem;

		&__grade {
			background-color: #CBD5E1;
			clip-path: circle();
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			padding: 1rem;
			font-weight: 700;
			font-size: 2.5rem;

			&__percent {
				height: 0.7rem;
				font-size: 1rem;
			}
		}
		&__gradebook_properties {
			display: flex;
			flex-direction: column;

			&__name {
				font-size: 2rem;
				font-weight: 500;
				overflow: clip;
				white-space: nowrap;
				max-width: 14ch;
			}
			&__course {
				font-weight: 400;
				font-size: 1.25rem;
			}
		}
	}

	&__link_container {
		display: flex;
		justify-content: center;
		flex-grow:1;
    align-items: flex-end;
	}
	
}
