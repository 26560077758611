@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

html, body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;

@mixin shorter {
   @media (max-height: #{750px}) {
       @content;
   }
}

@mixin short {
   @media (max-height: #{900px}) {
       @content;
   }
}

@mixin sm {
   @media (min-width: #{$screen-sm-min}) {
       @content;
   }
}

// Medium devices
@mixin md {
   @media (min-width: #{$screen-md-min}) {
       @content;
   }
}

// Large devices
@mixin lg {
   @media (min-width: #{$screen-lg-min}) {
       @content;
   }
}

// Extra large devices
@mixin xl {
   @media (min-width: #{$screen-xl-min}) {
       @content;
   }
}

body, html {

		font-size: 12px;

		@include sm {
				font-size: 12px;
		}

		@include md {
				font-size: 14px;
		}

		@include lg {
				font-size: 16px;
		}
}

a {
		color: #334155;
		font-weight: 600;
		text-decoration: none;
}

.button {
		cursor: pointer;
		user-select: none;
		background-color: #0F172A;
		border-radius: 6px;
		box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.25);
		white-space: nowrap;

		text-align: center;
		font-weight: bold;
		color: #F8FAFC;
		height: 3rem;
		&--thin {
			height: 2rem;
		}
		padding-left: 2rem;
		padding-right: 2rem;
		display: inline-flex;
		gap: 0.5rem;
		align-items: center;
		justify-content: center;

		&--green {
				background-color: #047857;
				box-shadow: 0px 0px 4px 4px #04785733; // the extra least significant byte represents transparency
		}

		&--red {
				background-color: #B91C1C;
				box-shadow: 0px 0px 4px 4px #B91C1C33; // the extra least significant byte represents transparency
		}

		&--disabled {
				background-color: #64748b;
				box-shadow: none;
		}


		&--secondary {
				background: none;
				color: #475569;
				padding-left: 0rem;
				padding-right: 0rem;
				box-shadow: none;

				&--red {
						color: #B91C1C;
				}

				&--darktheme {
						color: white;
				}
		}
}

.mild_emph {
		font-weight:600;
}

.modal {
		$padding: 2rem;
		z-index: 10;
		position: fixed;
		
		margin: auto;
		background-color: white;
		max-width: 750px;
		display: flex;
		flex-direction: column;
		padding: $padding;
		
		height: calc(100% - 2 * $padding);
		width: calc(100% - 2 * $padding);
		top:0;
		left:0;
		justify-content: center;
		@include sm {
				width:calc($screen-sm-min - (2 * $padding));
				border-radius: 0.5rem;
				height: auto;
				top: 50%;
				left: 50%;
				// since top and left refer to top left corner of element
				// we correct the error
				transform: translate(-50%, -50%);

		}

		@include md {
				width:calc($screen-md-min - (2 * $padding));
		}
		
		&__header {
				font-size: 1.2rem;
				font-weight: bold;
				display: flex;
				align-items: center;
				gap:0.5rem;
				&__icon {
						color: #334155;
				}

				
		}

		

		&__text {
				@include sm {
						flex-grow: 1;
				}
				margin-bottom: 2rem;
				&__form {
						padding-top: 0.5rem;
				}
		}

		&__buttons {
				display: flex;
				gap: 1rem;
				&__secondary {
						flex-grow: 1;
						display: flex;
						gap: 1rem;
				}
		}

		&__container {
				display: flex;
				flex-direction: column;
				background-color: rgba(0,0,0,0.25);
				top:0;
				left:0;
				position:fixed;
				z-index: 9;
				width: 100%;
				height: 100%;
				margin: 0;
				padding:0;
		}
}

textarea, .payment_form__input, input:not([type=submit]):not([type=file]):not(.react-datetime-picker__inputGroup__input) {
		background: #F1F5F9;
		border: 3px solid #334155;
		border-radius: 8px;
		padding: 0.5rem;
		font-size: 1.1rem;

		&:disabled {
				border: 3px solid #64748B;
				background-color: #F8FAFC;
		}

		&.angry {
				border: 3px solid #B45309;
		}
		
}

.nodisplay {
	display: none
}

.genericError {
				color: #d97706;
				font-weight: bold;
}

.green {
	color: #28E13B;
}

.rbtn {
	border-radius: 6px;
	box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.25);
	padding: 0.5rem;
	padding-left: 1rem;
	padding-right: 1rem;
	flex-grow:1;
	font-weight: 700;
	font-size: 20px;

	cursor:pointer;
	user-select: none;

	display: flex;
	justify-content: center; 
	

	&--split {
		justify-content: space-between;
	}

	&--selected {
		background-color: #15803D;
		color: white;
		box-shadow: none;
	}
}

.rbtns {
	display: inline-grid;
	gap: 1rem;
	grid-template-columns: 1fr 1fr;
	width: 100%;
}

.chip {
	display: flex;
	background-color: #0F172A;
	color: #F8FAFC;
	border-radius: 8px;
	width: max-content;
	align-items: center;

	font-size: 0.8rem;
	padding: 0.5em;
	padding-left: 1em;
	padding-right: 1em;
	gap: 0.5em;


	&__icon {
		color: #E2E8F0;
		display:flex;
		align-items: center;
	}

	&--white {
		color: #0F172A;
		background-color: white;

		.chip__icon {
			color: #1E293B;
		}
	}

	&__contents {
		vertical-align: middle;
		font-weight: 700;
	}

	&--light {
		background-color: white;

		.chip {
			&__icon {
				color: #1E293B;
			}
			color: #0F172A;
		}
	}
}

.fixicon {
	font-size: 1em !important;
}

.person {
	background-color: white;
	border-radius: 12px;
	display: flex;
	gap: 0.75rem;
	align-items: center;
	padding: 1rem;
	width: max-content;

	&__details {
		display: flex;
		flex-direction: column;

		&__item {
			&--name {
				font-weight: 700;
			}

			&--payment {
				font-weight: 600;
			}
		}

		&__subicon {
			float:right;
			color: hsla(217, 33%, 17%, 1);
			visibility: hidden;
			&--active {
				visibility: visible;
			}
		}
	}

	&__profile {
		height: 3rem;
		clip-path: circle();

		&--text {
			height: 3rem;
			width: 3rem;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size:2rem;
			background: hsla(214, 32%, 91%, 1);
			clip-path: circle();
			text-align: center;
		}
	}
}

.fake {
	background-color: #334155;
	color: #334155;
	width: max-content;
	user-select: none;
	border-radius: 0.25em;
}

.sidebar {
	background-color: #F8FAFC;
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 2rem;

	width: 100vw;

	@include sm {
		min-height: 100vh;
		width: 20rem;
		flex-direction: column;
		justify-content: flex-start;
	}

	&__profilecard {
		&--clickable {
			cursor:pointer;
		}
		justify-self: flex-start;
		flex-direction: row;
		align-self: center;
		gap:2rem;
		justify-content: center;
		@include sm {
			width: 100%;
			padding-top:2rem;
			padding-bottom:2rem;
			&--student {
				padding-top:4rem;
				padding-bottom:0rem;
				gap:0rem;
			}
		}

		display: flex;


		&__photo {
			width: 4rem;
			@include md {
				width: 5rem;
			}
			clip-path: circle();
		}

		&__info {
			font-size: 1.5rem;
			display: none;
			@include sm {
				display: flex;
			}
			flex-direction: column;
			justify-content: center;
		}

		&__name {
			font-weight: 500;
		}

		&__role {
			color: #334155;
		}
	}

	&__buttons {
		justify-self: center;
		display: flex;
		align-items: flex-end;
		gap: 2rem;
		@include sm {
			align-items: flex-start;
			flex-direction: column;
			gap: 1rem;
		}
	}

	&__button {
		font-weight: 500;
		display: flex;
		@include sm {
			flex-direction: row;
		}
		flex-direction: column-reverse;
		height:7rem;
		align-items: center;
		gap: 1rem;
		font-size: 4rem;
		@include sm {
			font-size: 1.5rem;
			height:3rem;
		}
		cursor: pointer;

		&__text {
			color: #475569;
			display: none;
			@include sm {
				display: block;
			}
		}

		&__icon {
			//font-size: 1.75rem;
			color: #64748B;
			display: flex;
			align-items: center;
		}

		&__indicator {
			background-color: #94A3B8;
			height: 0.75rem;
			width: 100%;

			border-top-left-radius: 0.25rem;
			border-top-right-radius: 0.25rem;

			@include sm {
				width: 0.75rem;
				height: 100%;
				border-top-left-radius: 0;

				border-top-right-radius: 0.25rem;
				border-bottom-right-radius: 0.25rem;
			}
			visibility: hidden;

			&--active {
				visibility: visible;
			}
		}
	}
}

.tutorpanel {
	display: flex;
	flex-direction: column-reverse;
	justify-content: stretch;
	position:fixed;
	height: 100%;
	width: 100%;

	gap: 0rem;
	@include sm {
		flex-direction: row;
		align-items:stretch;
		gap: 1rem;
	}

	@include md {
		gap: 2rem;
	}

	@include lg {
		gap: 3rem;
	}

	@include xl {
		gap: 4rem;
	}
}

.assurance {
		display: flex;
		gap: 0.5rem;
		align-items: center;
		&__icon {
				color: #059669;
				font-size: inherit;
		}
}

.assurances {
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loader {
	@keyframes textload_blink {
		0% {opacity: 0.5;}
		50% {opacity: 1}
		100% {opacity: 0.5}
	}

	&--spinner {
		height: 1em;
		flex-grow: 0;
		animation: rotating 1s linear infinite;
		align-self: center;
		transform-origin: 50% 50%;
	}

	&--text {
		background-color: #475569;
		color: #475569;
		user-select: none;
		border-radius: 0.5rem;
		animation: textload_blink 0.75s infinite;
	}

	.loader--text.loader--light {
		background-color: #f1f5f9;
		color: #f1f5f9;
		user-select: none;
	}
}

.iconbutton {

	color: #0F172A;
	background-color: #E2E8F0;
	
	&--primary {
		background-color: #0F172A;
		color: white;
	}

	clip-path: circle();
	border: none;
	cursor:pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.5rem;
}

// Groups a label and a text input (generic, some of this may be duplicated in the paymentform)
.generic_form {
	&__inputgroup {
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
		flex-grow: 1;
	}

	&__inputs {
		display: flex;
		flex-direction: column;
		flex-wrap:wrap;
		@include xl {
			flex-direction: row;
		}
		width: 100%;
		gap: 1rem;
	}

	&__label {
		font-weight: 600;
		color: #1E293B;
	}

	&__input {

		// shortens the width for inputs that only take in a few chars or digits
		// normal size for screens below lg 
		&--short {
			@include xl {
				width:6ch;
			}
		}
	}

	&__reactselect {
		@include xl {
			width: 18ch;
		}
	}
}
